import moment from "moment";

export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 50,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "出库通知单号",
    dataIndex: "number",
    sorter: true,
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: "状态",
    dataIndex: "status",
    width: 80,
    ellipsis: true,
    customRender: (value, item, index) => {
      return item.is_void ? "已作废" : item.is_completed ? "已完成" : "进行中";
    },
  },
  {
    title: "打印状态",
    dataIndex: "is_print",
    width: 80,
    customRender: (value) => {
      return value ? "已打印" : "未打印";
    },
  },
  {
    title: "客户",
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "承运公司",
    dataIndex: "express",
    width: 100,
  },
  {
    title: "物流单号",
    dataIndex: "logistics_number",
    width: 160,
    scopedSlots: { customRender: "logistics_number" },
  },
  {
    title: "预计出库日期",
    dataIndex: "delivery_date",
    width: 120,
    sorter: true,
    customRender: (value) => {
      return value ? moment(value).format("YYYY-MM-DD") : "";
    },
  },
  {
    title: "客户单号",
    dataIndex: "external_number",
    width: 160,
  },
  {
    title: "收货人",
    dataIndex: "customer_contact",
    width: 160,
  },
  {
    title: "备注",
    dataIndex: "remark",
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    sorter: true,
    width: 160,
  },
  {
    title: "操作",
    dataIndex: "action",
    fixed: "right",
    width: 230,
    ellipsis: true,
    scopedSlots: { customRender: "action" },
  },
];
